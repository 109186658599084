import React from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'rehype-parse';
import remark2rehype from 'remark-rehype';
import raw from 'rehype-raw';
import sanitize from 'rehype-sanitize';
import minifyWhiteSpace from 'rehype-minify-whitespace';
import rehype2react from 'rehype-react';
import merge from 'lodash/merge';
import gh from 'hast-util-sanitize/lib/github';

const baseSchema = {
  attributes: { '*': ['className'] },
};

export const createHtmlProcessor = ({ schema = {}, components = {} } = {}) => {
  return unified()
    .use(html)
    .use(sanitize, merge({}, gh, baseSchema, schema))
    .use(minifyWhiteSpace)
    .use(rehype2react, {
      Fragment: React.Fragment,
      createElement: React.createElement,
      components,
    });
};

export const createMdProcessor = ({ schema = {}, components = {} } = {}) => {
  return unified()
    .use(markdown)
    .use(remark2rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(sanitize, merge({}, gh, baseSchema, schema))
    .use(minifyWhiteSpace)
    .use(rehype2react, {
      Fragment: React.Fragment,
      createElement: React.createElement,
      components,
    });
};

// default processor
const htmlProcessor = createHtmlProcessor();
const mdProcessor = createMdProcessor();

export const html2react = (html) => htmlProcessor.processSync(html).result;
export const md2react = (md) => mdProcessor.processSync(md).result;
